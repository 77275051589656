var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "coupon-gallery" }, [
    _c("div", {
      staticStyle: { width: "100%" },
      attrs: {
        id: "ValassisGallery",
        "data-getconsumerdata": "getConsumerData"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }