<template>
  <v-container class="coupon-gallery">
    <div
      id="ValassisGallery"
      style="width: 100%"
      data-getconsumerdata="getConsumerData"
    ></div>
  </v-container>
</template>
<style lang="scss">
.coupon-gallery {
  padding: 10px 10px;
  iframe {
    min-height: 200px;
  }
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  name: "CouponGallery",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    handleGetConsumerData(loginRequired, callback) {
      var encrypted_data = this.user.saviConsumerData;

      callback(encrypted_data);
    }
  },
  mounted() {
    // Load the external script
    const script = document.createElement("script");
    script.id = "valassisDynamicScript";
    script.src = "https://coupongallery.galasupermercatimobile.it/scripts/core/util/init.js";
    script.async = false;
    script.defer = true;

    script.onload = () => {
      // Check if the valassis object is defined
      if (window.valassis && typeof window.valassis.expand === "function") {
        // The script has been loaded, and the valassis object is defined
        window.valassis.expand();
        window.valassis.getConsumerData = this.handleGetConsumerData;
      } else {
        console.error("valassis object or expand method not found.");
      }
    };

    script.onerror = () => {
      console.error("Failed to load the external script.");
    };

    document.head.appendChild(script);
  },
  beforeDestroy() {
    const script = document.getElementById("valassisDynamicScript");
    if (script) {
      script.parentNode.removeChild(script);
    }
  }
};
</script>
